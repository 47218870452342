import { StripeError } from '@stripe/stripe-js';
import { MyOrg, UserInfo } from '../lib/models';
import { AxiosError } from 'axios';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';
import { axiosCluster, axiosController, axiosOrgService } from '@/lib/network';
import { getAxiosInstance } from '@tigergraph/tools-models';

export const getUserFullname = (user: UserInfo): string => {
  if (!user.email) {
    return 'unknown';
  }

  if (!user.firstName && !user.lastName) {
    const index = user.email.indexOf('@');
    return user.email.slice(0, index).split('.').join(' ');
  }

  return user.firstName + ' ' + user.lastName;
};

export function getErrorMessage(error: AxiosError<any, any> | Error | StripeError) {
  let message = error.message;

  if ('response' in error) {
    // check for axios error
    if (error.response?.data?.message) {
      // error message from rest api
      message = error.response?.data?.message;
    } else if (error.response?.data?.Message) {
      message = error.response?.data?.Message;
    }
  } else if (error instanceof AxiosError) {
    message = 'A network error occurred. ' + 'This could be a CORS issue or a dropped internet connection.';
  }

  if (!message) {
    message = '';
  }

  return message;
}

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  // keep one decimal
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}

export function getGrafanaURL(orgId: string, workspaceId: string, workgroupId: string, themeType: 'light' | 'dark') {
  let grafanaURL: string = import.meta.env.VITE_GRAFANA_URL;
  const searchParams = new URLSearchParams();
  let queryParams: Record<string, string> = {
    'var-orgId': orgId,
    refresh: '30s',
    'var-ServiceName': 'All',
    'var-pod': 'All',
    'var-workspaceId': workspaceId,
    'var-workgroupId': workgroupId,
    auth_token: sessionStorage.getItem(ID_TOKEN_KEY)!,
    theme: themeType,
  };
  Object.keys(queryParams).forEach((key) => {
    searchParams.set(key, queryParams[key]);
  });
  grafanaURL += `?${searchParams.toString()}&&kiosk`;
  return grafanaURL;
}

export function hasReadWritePermission(privileges: Array<string>): boolean {
  if (
    privileges &&
    privileges.includes('READ_SCHEMA') &&
    privileges.includes('READ_QUERY') &&
    privileges.includes('READ_DATA') &&
    privileges.includes('WRITE_QUERY')
  ) {
    return true;
  }
  return false;
}

export function getGraphListFromSimpleAuth(simpleAuth: any) {
  const graphList: string[] = [];
  const privileges = simpleAuth['privileges'];

  let hasGlobalPrivileges = false;
  if (
    Object.keys(privileges).includes('1') &&
    ((Array.isArray(privileges['1']) && hasReadWritePermission(privileges['1'])) ||
      hasReadWritePermission(privileges['1']['privileges']))
  ) {
    hasGlobalPrivileges = true;
  }

  for (const graphName of Object.keys(privileges).filter((q) => q !== '1')) {
    if (hasGlobalPrivileges) {
      graphList.push(graphName);
    } else {
      const graphPrivileges = privileges[graphName];

      if (
        (Array.isArray(graphPrivileges) && hasReadWritePermission(graphPrivileges)) ||
        hasReadWritePermission(graphPrivileges['privileges'])
      ) {
        graphList.push(graphName);
      }
    }
  }
  return graphList;
}

export function logoutClearance() {
  const localEnv = window.location.hostname.includes('localhost');

  // Clear local storage except loggedin flag.
  localStorage.clear();

  const mainDomain = localEnv ? 'tgcloud-dev.com' : getMainDomain();

  // Remove idToken from cookie.
  const domain = localEnv ? '' : `domain=${mainDomain}`;
  document.cookie = `idToken=;path=/;max-age=0;${domain}`;

  delete axiosCluster.defaults.headers.common['Authorization'];
  delete axiosController.defaults.headers.common['Authorization'];
  delete axiosOrgService.defaults.headers.common['Authorization'];
  delete getAxiosInstance().defaults.headers.common['Authorization'];
}

export function getMainDomain() {
  return window.location.host.split('.').slice(-2).join('.');
}

export function shouldShowBackupRestore(userInfo: UserInfo, workgroupId: string) {
  const roles = userInfo.roles;
  if (roles.map((role) => role.name).includes('super-admins')) {
    return true;
  } else if (roles.map((role) => role.path).includes(`/${workgroupId}/workgroup-admins`)) {
    return true;
  } else if (roles.find((role) => role.path.match(`/${workgroupId}/.*/workspace-admins`))) {
    return true;
  }
  return false;
}

export function getSupportMailLink({
  userInfo,
  currentOrg,
  subject,
}: {
  userInfo?: UserInfo;
  currentOrg?: MyOrg;
  subject?: string;
}) {
  if (userInfo && currentOrg) {
    const url = `mailto:savanna-support@tigergraph.com?subject=TigerGraph Savanna Support ${
      subject ? `- ${subject}` : ''
    } - ${userInfo.email} - ${currentOrg.org_id} - ${currentOrg.org_name}&body=${getSupportRequestMailBody()}`;
    return url;
  }
  return `mailto:support@tigergraph.com?subject=TigerGraph Savanna Support&body=${getSupportRequestMailBody()}`;
}

export function getSaleMailLink() {
  return `mailto:sales@tigergraph.com?subject=TigerGraph Savanna Sales Support&body=${getSaleRequestMailBody()}`;
}

function getSupportRequestMailBody(): string {
  // %0A encoded for new line.
  const bodyText = 'Hello Support Team,%0A%0A' + 'Regards,%0A';

  return bodyText;
}

function getSaleRequestMailBody(): string {
  // %0A encoded for new line.
  const bodyText = 'Hello Sales Team,%0A%0A' + 'Regards,%0A';

  return bodyText;
}

export function validateBackupTag(tag: string) {
  const pattern = /^[a-zA-Z0-9\-_]+$/;
  return pattern.test(tag);
}

export function isVertex(obj: any) {
  return obj?.v_id || obj?.attributes || obj?.v_type;
}

export function isEdge(obj: any) {
  return obj?.e_type || obj?.attributes;
}
